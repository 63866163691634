<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Host Details</h5>
                <div>
                    <Button label="Back" class="p-button-sm p-mr-1" @click="goToBack()" icon="pi pi-chevron-left" />
                </div>
            </div>
            <div class="card">
                <div class="p-col-12">
                    <div class="card p-mt-2 p-ml-3 p-mr-2 p-mb-2">
                        <div class="p-grid p-mt-2">
                            <div class="p-col-12 p-md-2 p-as-center" style="width: 15%">
                                <div class="box p-text-center">
                                    <span v-if="hostData.ac18">
                                        <img :src="'https://storage.googleapis.com/' + storage_path + '/hosts/Logo/' + data.ac18"
                                            width="120" height="75"
                                            style="vertical-align: middle; border: 2px solid #ecebeb" />
                                    </span>
                                    <span v-if="!hostData.ac18">
                                        <img :src="'/assets/layout/images/default_hotel.jpeg'" width="120" height="75"
                                            style="vertical-align: middle; border: 2px solid #ecebeb" />
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-3" style="width: 23%">
                                <div class="box p-text-capitalize overflow-span"><span class="p-text-bold">Marketing Name :
                                    </span>{{
                                        hostData.ac28 ? hostData.ac28 : 'N/A' }}</div>
                                <div class="box p-mt-3 p-text-capitalize overflow-span"><span class="p-text-bold">Brand :
                                    </span>{{
                                        hostData.ac3 ? hostData.ac3 : 'N/A' }}</div>
                                <div class="box p-mt-3 p-text-capitalize overflow-span"><span class="p-text-bold">State :
                                    </span>{{
                                        hostData.ac11 ? hostData.ac11 : 'N/A' }}</div>
                                <div class="box p-mt-3 p-text-capitalize overflow-span"><span class="p-text-bold">City :
                                    </span>{{
                                        hostData.ac13 ? hostData.ac13 : 'N/A' }}</div>
                                <div class="box p-mt-3 p-text-capitalize overflow-span"><span class="p-text-bold">Area :
                                    </span>{{
                                        hostData.ac15 ? hostData.ac15 : 'N/A' }}</div>
                            </div>
                            <Divider layout="vertical" />
                            <div class="p-col-12 p-md-3" style="width: 23%">
                                <div class="box p-text-capitalize overflow-span"><span class="p-text-bold">Contact Person :
                                    </span>{{
                                        hostData.ac6 ? hostData.ac6 : 'N/A' }}</div>
                                <div class="box p-mt-3 p-text-capitalize overflow-span"><span class="p-text-bold">Designation :
                                    </span>{{
                                        hostData.ac9 ? hostData.ac9 : 'N/A' }}</div>
                            </div>
                            <Divider layout="vertical" />
                            <div class="p-col-12 p-md-4" style="width: 27%">
                                <div class="box p-text-capitalize"><span class="p-text-bold">GST Number : </span>{{
                                    hostData.ac4 ? hostData.ac4 : 'N/A' }}</div>
                                <div class="box p-mt-3 p-text-capitalize"><span class="p-text-bold">PAN Number : </span>{{
                                    hostData.ac5 ? hostData.ac5 : 'N/A' }}</div>
                                <div class="box p-mt-3 p-text-capitalize"><span class="p-text-bold">Host Status : </span>
                                    <span v-if="hostData.ac32 == 1"> Verified </span>{{ }}
                                    <span v-if="hostData.ac32 == 0"> Non-Verified </span>
                                </div>
                                <div class="box p-mt-3 p-text-capitalize"><span class="p-text-bold">Highest Host
                                        Consumption: </span>{{
                                            hostData.ac33 ? hostData.ac33 : 'N/A' }}</div>
                            </div>
                        </div>

                        <Divider />
                        <TabView @tab-change="getTabEvents">
                            <TabPanel header="Host Users">
                                <div class="card">
                                    <DataTable :value="HostUserDetailsList" :lazy="true" :paginator="true" :rows="30"
                                        :totalRecords="HostUsertotalRecords" :loading="loading" @page="onPageUser"
                                        class="p-datatable-users" data-key="ad1" :rowHover="true"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                        :style="{ 'overflow-wrap': 'break-word' }">
                                        <template #empty> No data found. </template>
                                        <template #loading> Loading data. Please wait... </template>
                                        <Column header="User Name" headerStyle="width: 30%">
                                            <template #body="{ data }">

                                                <div class="p-text-capitalize overflow-span">
                                                    <span v-if="data.ad19 != null && data.ad19 != ''">
                                                        <img :src="data.ad19" height="50" width="50"
                                                            style="vertical-align: middle; border-radius: 75px" />
                                                    </span>
                                                    <span v-else>
                                                        <img src="/assets/layout/images/widgets/Image_not_available.png"
                                                            height="50" width="50"
                                                            style="vertical-align: middle; border-radius: 75px" />
                                                    </span>
                                                    <span> &nbsp; {{ data.ad9 ? data.ad9 : "-" }}
                                                    </span>
                                                </div>
                                            </template>
                                        </Column>

                                        <Column header="Designation" headerStyle="width: 20%">
                                            <template #body="{ data }">
                                                <span class="overflow-span p-text-capitalize">
                                                    {{ data.ad11 ? data.ad11 : "-" }}</span>
                                            </template>
                                        </Column>
                                        <Column header="Email Id" headerStyle="width: 20%">
                                            <template #body="{ data }">
                                                <span class="overflow-span">{{ data.ad18 ? data.ad18 : "-" }}</span>
                                            </template>
                                        </Column>
                                        <Column header=" Contact Number" headerStyle="width: 15%">
                                            <template #body="{ data }">
                                                <span>{{ data.ad15 ? data.ad15 : "-" }}</span>
                                            </template>
                                        </Column>
                                        <Column header="Status" headerStyle="width: 15%" class="p-text-center">
                                            <template #body="{ data }">
                                                <div class="p-text-capitalize overflow-span">
                                                    <div v-if="data.ae18 == 1"> Active </div>
                                                    <div v-else-if="data.ad44 == 2"> Temporary suspended </div>
                                                    <div v-else> Permanently suspended</div>
                                                </div>
                                            </template>
                                        </Column>
                                    </DataTable>
                                    <Toast />
                                </div>
                            </TabPanel>
                            <TabPanel header="Branch Details">
                                <div class="card">
                                    <DataTable :value="branchDetailsList" :lazy="true" :paginator="true" :rows="30"
                                        :totalRecords="branchtotalRecords" :loading="loading" @page="onPageBranch"
                                        class="p-datatable-users" data-key="ae1" :rowHover="true"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                        :style="{ 'overflow-wrap': 'break-word' }">
                                        <template #empty> No data found. </template>
                                        <template #loading> Loading data. Please wait... </template>
                                        <Column header="Branch Name" headerStyle="width: 30%">
                                            <template #body="{ data }">

                                                <div class="p-text-capitalize overflow-span">
                                                    <span>{{ data.ae5 ? data.ae5 : "-" }}
                                                    </span>
                                                </div>
                                            </template>
                                        </Column>

                                        <Column header="Branch Category" headerStyle="width: 20%">
                                            <template #body="{ data }">
                                                <span class="overflow-span p-text-capitalize">
                                                    {{ data.ae14 ? data.ae14 : "-" }}</span>
                                            </template>
                                        </Column>
                                        <Column header="Address" headerStyle="width: 20%">
                                            <template #body="{ data }">
                                                <span class="overflow-span p-text-capitalize">{{ data.ae11 ? data.ae11 : "-" }}</span>
                                            </template>
                                        </Column>
                                        <Column header="City" headerStyle="width: 15%">
                                            <template #body="{ data }">
                                                <span class="p-text-capitalize">{{ data.ae7 ? data.ae7 : "-" }}</span>
                                            </template>
                                        </Column>
                                        <Column header="Area" headerStyle="width: 15%">
                                            <template #body="{ data }">
                                                <span class="p-text-capitalize">{{ data.ae9 ? data.ae9 : "-" }}</span>
                                            </template>
                                        </Column>
                                        <Column header="Status" headerStyle="width: 15%">
                                            <template #body="{ data }">
                                                <div class="p-text-capitalize overflow-span">
                                                    <div v-if="data.ae18 == 1"> Active </div>
                                                    <div v-else> Inactive </div>
                                                </div>
                                            </template>
                                        </Column>
                                    </DataTable>
                                    <Toast />
                                </div>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
                <Toast />
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../service/ApiService';
import { useRoute } from 'vue-router';
import router from '@/router';
export default {
    data() {
        return {
            hostData: [],
            loading: false,
            HostUserDetailsList: [],
            Hostuserdatadisplayflag: true,
            HostUsertotalRecords: 0,
            tabIndex: 0,
            branchDetailsList: [],
            branchtotalRecords: 0,
            branchdatadisplayflag: false,
            showBranchUsersDialog: false,
            page_no: 0,
            itemsPerPage: 30
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
        const route = useRoute();
        this.routeParam = route.params;
    },
    mounted() {
        this.getHostAllDetails({ clientId: this.routeParam.clientId, hostId: this.routeParam.hostId });
        this.getHostUserDetails({ clientId: this.routeParam.clientId, hostId: this.routeParam.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
        this.getHostBranchesDetails({ clientId: this.routeParam.clientId, hostId: this.routeParam.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
    },
    methods: {
        getHostAllDetails(ev) {
            this.loading = true;
            this.ApiService.getHostAllDetails(ev).then((data) => {
                if (data.status == 200) {
                    this.hostData = data.data;
                    this.loading = false;
                } else {
                    this.hostData = '';
                    this.loading = false;
                }
            });
        },
        getTabEvents(ev) {
            this.page_no = 0;
            this.tabIndex = ev.index;
        },
        onPageUser(e) {
            this.loading = true;
            this.tabIndex = 0;
            this.page_no = e.page;
            this.getHostUserDetails();
        },
        onPageBranch(event) {
            this.loading = true;
            this.page_no = event.page;
            this.tabIndex = 1;
            this.getHostBranchesDetails();
        },
        getHostBranchesDetails() {
            this.loading = true;
            this.ApiService.getHostBranchesDetails({ clientId: this.routeParam.clientId, hostId: this.routeParam.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.branchDetailsList = data.data;
                    this.branchtotalRecords = data.count;
                    this.loading = false;
                    this.branchdatadisplayflag = true;
                }
            });
        },
        getHostUserDetails() {
            this.loading = true;
            this.ApiService.getHostUserDetails({ clientId: this.routeParam.clientId, hostId: this.routeParam.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.HostUserDetailsList = data.data;
                    this.HostUsertotalRecords = data.count;
                    this.loading = false;
                    this.Hostuserdatadisplayflag = true;
                }
            });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        formatCurrency(amount, currency = 'INR', minimumFractionDigits = 2) {
            let formatterAmount = new Intl.NumberFormat('en-IN', { minimumFractionDigits }).format(amount.toString());
            return (+0).toLocaleString('en-IN', { style: 'currency', currency })
                .replace('0.00', " " + formatterAmount)
        },
        goToBack() {
            router.push({
                name: "listhosts",
            });
        },
    },
};
</script>
<style scoped lang="scss">
.download-btn {
    padding: 10px;
    border-radius: 50%;
    height: 2.75rem;
    color: #ffffff;
    background: #3c6895;
    border: 0 none;
    width: 38.5px;
    display: inline-block;
}

.overflow-span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    /* Optionally set a maximum width */
}
</style>
